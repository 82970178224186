
import './App.css';
import GisMapContainer from './component/map/map.contianer'
function App() {
  return (
    <div className="App">
       
       <GisMapContainer />
   
    </div>
  );
}

export default App;
