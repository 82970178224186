import React, { useEffect, useRef, useState } from 'react'
import { MapContainer, TileLayer, useMap, Marker, Popup, GeoJSON, LayersControl } from 'react-leaflet';
import { SearchControl, OpenStreetMapProvider } from 'react-leaflet-geosearch'
import 'leaflet/dist/leaflet.css'
import ResetViewControl from '@20tab/react-leaflet-resetview';
import damsData from '../../data/dams.json'
import districtsData from '../../data/districts.json'
const layerDamStyle = {
    color: "#53565A",
    fillColor: "green"
}

const layerDistrictStyle = {
    color: "#53565A",
    fillColor: "#90ADC6",
weight:0.5
}


export default function GisMapContainer() {
    const [selection, setSelection] = useState("")
    const mapRef = useRef()

    const prov = OpenStreetMapProvider();
    const GeoSearchControlElement = SearchControl;
    function onEachDamFeature(feature, layer) {

        var damName = feature?.properties?.Sation_Nam
        var river = feature?.properties?.River
        var popConent = "Dam : " + damName + "\n" + " River : " + " " + river;
        layer.bindPopup(popConent)

    }

    function onEachDistrictFeature(feature, layer) {
        var districtName = feature?.properties?.DISTRICT
        var popConent = "District : " + districtName + "\n"
        layer.bindPopup(popConent)
        layer.on('click', (event) => {

            setSelection(districtName)
            event?.target.setStyle({ fillColor: "#FAD02C", fillOpacity: 0.8, border: 0.5 })
        }
        )

    }

    function filterGeo(feature) {

    }

    return (
        <div>
            <MapContainer ref={mapRef} style={{ height: "100vh" }} zoom={7} center={[23.30, 80.00]}>
                <ResetViewControl
                    title="Reset Map"
                    icon="url('https://th.bing.com/th/id/R.fe64ce4ee64670405c6c20e6bc0d23f5?rik=scrUKLD%2bL%2fJT6A&riu=http%3a%2f%2fcdn.onlinewebfonts.com%2fsvg%2fimg_178086.png&ehk=1dIhDxEz61hh%2belWthaYr7qoR2tbR3smwvBnQpKBwbg%3d&risl=&pid=ImgRaw&r=0')"
                />
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LayersControl position="topright">

                    <LayersControl.Overlay checked name="Dams">
                        <GeoJSON key='damLayer' data={damsData?.features} style={layerDamStyle} onEachFeature={onEachDamFeature} ></GeoJSON>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay name="Districts">
                        <GeoJSON key='districtLayer' data={districtsData?.features} style={layerDistrictStyle} onEachFeature={onEachDistrictFeature} ></GeoJSON>
                    </LayersControl.Overlay>
                </LayersControl>

            </MapContainer>

        </div>
    )
}
